.introduction-page {
  display: flex;
}

.title-text {
  color: #f88802;
  position: absolute;
  top: 15%;
  left: 10%;
  width: 80%;
  max-height: 80%;
  z-index: 1;
}

.author-name {
  font-size: 20px;
  font-weight: 800;
  color: white;
}



.title-text-word {
  font-size: 100px;
  font-weight: 1000;
  display: block;
  z-index: 2;
}






.backdrop-shape {
  position: absolute;
  top: 10%;
  left: 30%;
  z-index: 0;
  height: 80%;
  width: 30%;
  background-color: #006ad0;
  border-radius: 10px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

}

.backdrop-shape-two {
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 1;
  height: 60%;
  width: 80%;
  background-color: #f88802;
  clip-path: circle(45%);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

}


@media (max-width: 600px) {
  .title-text {
    position: absolute;
    top:20%;
    left: 5%;

  }
  .title-text-word {
    font-size: 80px;
  }
  .backdrop-shape {
    position: absolute;
    top: 10%;
    left: 0;
    width: 80%;
    height: 40%;
    border-radius: 20px;
  }
  .backdrop-shape-two {
    display: none;
  }
}


@media only screen and (max-width: 992px) and (min-width: 600px) {
  .backdrop-shape {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 60%;
    height: 80%;
    border-radius: 20px;
  }
  
    .title-text-word {
    font-size: 110px;
  }
  .author-name {
    font-size: 26px;
    margin-top: 5rem;
  }
  
  .backdrop-shape-two {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .backdrop-shape-two {
    height: 500px;
    width: 500px;
    border-radius: 250px;
  }
}

