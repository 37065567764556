.project-page {

  display: block;
  height: 100%;
  width: 100%;
  margin-top: 30px;
}
img {
  width: 110%;
  height: 100%;
  border-radius: 20px;
}

.project-name {
  color: #0a2f80;
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  height: auto;
  width: 40%;
  position: relative;
  left: 40%;
  top: -2rem;
  margin-top: 5rem;
  margin-bottom: 2rem;
  text-align: right;
  
}

.h-line {
  display: block;
  border-top: solid 3px #f88802;
  border-radius: 3px;
  height: 1px;
  width: 60%;
  transform-origin: 0%;
  max-width: 70%;
  position: absolute;
  right: 0;
}

.project-preview {
  margin-left: auto;
  margin-right: auto;
  opacity: 0.6;
  width: 25%;
  height: 40%;
  text-align: center;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  position: relative;
  left: -15rem;
  top: -5rem;
  border-radius: 20px;
}

.project-intro {
  margin-left: auto;
  margin-right: auto;
  background-color: #0a2f80;
  color: white;
  font-size: 18px;
  width: 35%;
  height: 300px;
  text-align: left;
  padding: 1rem;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  display: block;
  border-radius: 10px;
  position: relative;
  bottom: 200px;
  left: 10rem;
  overflow-y: scroll;
}



.project-keywords {
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  margin-left: 50%;
  font-size: 18px;
  font-weight: 600;
  color: #fecc51;
  border: none;
  width: auto;
  display: flex;
  text-align: right;
  position: static;
  right: 0;
  bottom: 0;
}

.keyword {
  padding: 0 1rem;
}


@media (max-width: 600px) {
  .project-page {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .project-name {
    color: #0a2f80;
    font-size: 20px;
    font-style: italic;
    font-weight: 800;
    height: auto;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-bottom: 2rem;
    text-align: left;
  }
  .h-line {
    display: block;
    border-top: solid 3px #f88802;
    border-radius: 3px;
    height: 1px;
    width: 80%;
    transform-origin: 0%;
    max-width: 100%;
    position: static;
    top: 0;
  }
  .project-preview {
    position:static;
    width: 100%;
    max-width: 100%;
    height: 30%;
    margin-bottom: 10px;
  }

  .project-intro {
    width: 100%;
    height: 50%;
    max-height: 60%;
    position: static;
    margin: 10px auto;
    overflow-y: scroll;
  }
  .project-keywords {
    margin: 5px auto 0px auto;
    font-size: 16px;
    font-weight: 400;
    color: #fecc51;
    border: none;
    width: auto;
    display: flex;
    text-align: center;
    position: static;
    right: 0;
    bottom: 0;
  }
}


@media only screen and (max-width: 992px) {
  .project-page {
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .project-name {
    color: #0a2f80;
    font-size: 20px;
    font-style: italic;
    font-weight: 800;
    height: auto;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    margin-top: 0;
    margin-bottom: 2rem;
    text-align: left;
  }
  .h-line {
    display: block;
    border-top: solid 3px #f88802;
    border-radius: 3px;
    height: 1px;
    width: 80%;
    transform-origin: 0%;
    max-width: 100%;
    position: static;
    top: 0;
  }
  .project-preview {
    position:static;
    width: 50%;
    max-width: 100%;
    height: 30%;
    margin-bottom: 10px;
  }

  .project-intro {
    width: 60%;
    height: 50%;
    max-height: 60%;
    position: static;
    margin: 10px auto;
    overflow-y: scroll;
    font-size: 20px;
  }
  .project-keywords {
    margin: 5px auto 0px auto;
    font-size: 18px;
    font-weight: 400;
    color: #fecc51;
    border: none;
    width: auto;
    display: flex;
    text-align: center;
    position: static;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (min-width: 992px) {
  .project-preview {
    position:static;
    width: 40%;
    max-width: 100%;
    height: 40%;
    position: relative;
    left: -10rem;
    top: 0rem;
  }
  .project-intro {
    width: 50%;
  }
}

@media only screen and (min-width: 1200px) {
  
}