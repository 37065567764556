.tab-display {
  height: 80%;
  width: 80%;
  background-color: #006ad0;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 20px;
}

.time-tabs {
  justify-content: space-between;
  height: 100%;
  width: 20%;
  display: flex;
  flex-direction: column;
  background-color: #0a2f80;
  border-radius: 20px 0 0 20px;
}

.tab {
  display: flex;
  width: 100%;
  height: calc(100% / 4);
}

.time-tab-btn {
  text-align: center;
  border: none;
  background-color: #0a2f80;
  color: white;
  width: 100%;
  outline: none;
  padding: 0.5rem 0;
  border-radius: 20px 0 0 20px;
}

.time-tab-btn:focus {
  outline: none;
}

.tabSideline {
  width:1px;
  border-left: 2px #fecc51 solid;
  height:  100%;
}

.work-content {
  width: 80%;
  height:100%;
  padding: 1rem;
  display: block;
  text-align: left;
  background-color: #006ad0;
  border-radius:  0 20px 20px 0;
  overflow: scroll;
}

.job-company {
  font-size: 30px;
  font-weight: bolder;
  color: #f88802;
  padding: 10px 20px;

}

.job-details {
  width: 100%;
  height: auto;
  font-size: 24px;
  color: #e2e4e7;
  padding: 10px 20px;
}

.job-details > p {
  white-space: pre-wrap;
}


@media (max-width: 600px) {
  .tab-display {
    width: 90%;
    height: 100%;
  }
  .job-company {
    font-size: 24px;
  }
  .job-details {
    font-size: 18px;
  }
}
@media only screen and (max-width: 768px) {
  .tab-display {
    width: 90%;
    height: 100%;
  }
  .job-company {
    font-size: 24px;
  }
  .job-details {
    font-size: 18px;
  }
}

@media only screen and (min-width: 992px) {
  
}

@media only screen and (min-width: 1800px) {
}