.about-page {
  color: #006ad0;
  width:100%;
  position: relative;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content:  space-evenly;
  overflow: scroll;
}

.about-content {
  padding: 1rem 1rem;
  margin-bottom: 2rem;
  font-size: 28px;
  font-weight: bold;
  display: flex;
  align-items: center;
  z-index: 1;
}

.about-content.message-0, .about-content.message-2 {
  max-width: 50%;
  margin-right: calc(50% - 20px);
  position: relative;
}

.about-content.message-2 {
  
}


.about-content.message-1{
  max-width: 50%;
  margin-left: calc(50% - 20px);
  position: relative;
  
}

@media (min-width: 970px) {
  .about-content {
    font-size: 36px;
      
  }
  
}

.backdrop-shape-one-about {
  position: absolute;
  top: 10%;
  left: 20%;
  height: 80%;
  width: 60%;
  z-index: 0;
  background-color: #f88802;
  border-radius: 20px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

}

.backdrop-shape-two-about {
  display: none;
  position: absolute;
  top: 25%;
  left: 25%;
  height: 60%;
  width: 40%;
  clip-path: ellipse(90% 90% at 100% 0%);
  z-index: 0;
  background-color: #006ad0;
}


@media (max-width: 600px) {
  .about-page {
    height: 100%;
  }
  .about-content {
    display:block;
    font-size: 24px;
    margin-bottom: 0;
    padding: 10px;
  }
  .about-content.message-0, .about-content.message-1, .about-content.message-2 {
    width: calc(100%-20px);
    position: relative;
    max-width: 100%;
    margin: 10px;

  }
  .about-content.message-1 {
    text-align: right;
  }

}
@media (max-width: 992px) {
  .about-page {
    height: 100%;
  }
  .about-content {
    display:block;
    font-size: 24px;
    margin-bottom: 0;
    padding: 10px;
  }
  .about-content.message-0, .about-content.message-1, .about-content.message-2 {
    width: calc(100%-20px);
    position: relative;
    max-width: 100%;
    margin: 10px;

  }
  .about-content.message-1 {
    text-align: right;
  }
}

@media only screen and (min-width: 992px) {
  .about-content {
    font-size: 28px;
  }
}

@media only screen and (min-width: 1800px) {
  .about-content {
    font-size: 34px;
  }
}