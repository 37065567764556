.info-bar {
  background-color: #0a2f80;
  position: fixed;
  height: 100%;
  width: 5%;
  min-width: 5%;
  max-width: 5%;
  float: left;
  top: 0px;
  left: 0px;
  padding: 10rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.menu-icon {
  
  text-align: center;
  margin-bottom: 30px;

}

.icons-section {
  position: absolute;
  bottom: 0;
}


@media (max-width: 600px) {
  .info-bar {
    position: fixed;
    height: 70px;
    left: 0px;
    top: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    padding: 20px;
    z-index: 2;
    float: right;
    align-items: center;
  }
  .icons-section {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    margin: 20px 0;
  }
  .menu-icon {
    
    text-align: right;
    margin-right: 10px;

  }
}
@media (min-width: 600px) {

}

@media only screen and (min-width: 992px) {
  
}

@media only screen and (min-width: 1200px) {
}




