.experiences-page {
  margin-right: auto;
  margin-left: auto;
  padding-top: 5rem;
  height: 100%;
  min-width: 400px;
  width: 70%;
  display: block;
  text-align: center;
}

.tab-display-section {
  display: flex;
  padding-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  height: 80%;
  border-radius: 1rem;
}

.exp-page-title {
  font-size: 52px;
  color: #006ad0;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}

.resume-button {
  display: none;
  color: #e2e4e7;
  border: none;
  width: 16%;
  border-radius: 10px;
  height: 6%;
  background-color: #f88802;
  justify-content: space-between;

}

.resume-button > span {
  font-size: 20px;
  font-weight: bold;
}


@media (max-width: 600px) {
  .experiences-page {
    margin-right: auto;
    margin-left: auto;
    padding-top: 1rem;
    height: 100%;
    width: 100%;
    min-width: 200px;
    max-width: 100%;
    display: block;
    text-align: center;
  }
  .exp-page-title {
    font-size: 36px;
    color: #006ad0;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 992px) {
  .experiences-page {
    margin-right: auto;
    margin-left: auto;
    padding-top: 1rem;
    height: 100%;
    width: 100%;
    min-width: 200px;
    max-width: 100%;
    display: block;
    text-align: center;
  }
  .exp-page-title {
    font-size: 36px;
    color: #006ad0;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (min-width: 992px) {
  

}

@media only screen and (min-width: 1200px) {
}