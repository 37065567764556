

.skills-area {
  color: white;
  height: 100%;
  width: 100%;
  padding:0 2rem;
  display:flex;

}



.title {
  color: #f88802;
  display: block;
  width: 30%;
  height: 100%;
  padding: 0px 1rem;
  background-color: #006ad0;
}

.tl {
  font-weight: 800;
  font-size: 66px;
  margin: 0px;

}


.aoi {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 4rem;
  margin-left: 2rem;
  flex-grow: 1;
  flex-shrink: 1;
  height: 90%;
  width: 50%;
  background-color: #e2e4e7;
}

.areas {
  display: flex;
  
}

.aoi-title {
  border-top: #f88802 solid 5px;
  color : #001340; 
  font-size: 40px;
  font-weight: bold;
  width: 30%;
  max-width: 30%;
  display: flex;
  word-break: break-all;
  overflow-wrap: break-word;
}






.aoi-content {
  border-top: #f88802 solid 5px;
  color: #006ad0;
  width: 70%;
  height: 100%;
  padding: 0.5rem 1.5rem;
  position:relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: left;
  border-radius: 0 0px 10px 0;
}

.skill-icon {
  display: flex;
  text-align: center;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
}

h5 {
  font-size: 24px;
  font-weight: bold;
  margin-top: 0.5rem;
}

h6 {
    font-size: 20px;
  font-weight: bold;
  margin-top: 0.5rem;

}

.single-skill {
  display: block;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.list {
  font-size: 30px;
  display: inline;
  width: 80%;
  height: auto;
}

@media (max-width: 600px) {
  .skills-area {
    padding: 20px 10px;
    display: block;
    height: auto;
  }
  .title {
    color: #f88802;
    display: block;
    width: 100%;
    height: 25%;
    padding: 0px;
    background-color: #006ad0;

  }

  .tl {
    font-weight: 600;
    font-size: 40px;
    margin: 0px;

  }
  .aoi {
    display: block;
    margin: 10px;
    height: auto;
    width: 100%;
    background-color: #e2e4e7;
  }
  .aoi-title {
    border: none;
    color : #001340; 
    font-size: 28px;
    font-weight: bold;
    width: 50%;
    max-width: 50%;
    display: flex;
    word-break: break-all;
    overflow-wrap: break-word;
  }
  .areas {
    display: block;
    margin-bottom: 20px;
  }
  .aoi-content {
    border-top: #f88802 solid 3px;
    color: #006ad0;
    width: 100%;
    height: 100%;
    padding: 0.5rem 0;
    position:relative;
    display: flex;
    align-items: left;
    justify-content: left;
    border-radius: 0;
    overflow-y: scroll;
  }
  h5, h6 {
    font-size: 18px;
  }

  .skill-icon {
    font-size: 16px;
  }

}
@media only screen and (max-width: 992px) and (min-width: 600px) {
  
  .tl {
    font-size: 36px;
  }
  .aoi {
    display: block;
    margin: 10px;
    height: auto;
    width: 100%;
    background-color: #e2e4e7;
  }
  .aoi-title {

    color : #001340; 
    font-size: 28px;
    font-weight: bold;
    width: 50%;
    max-width: 50%;
    display: flex;
    word-break: break-all;
    overflow-wrap: break-word;
  }
  
  .aoi-content {

    color: #006ad0;
    width: 100%;
    height: 100%;
    padding: 0.5rem 0;
    position:relative;
    display: flex;
    align-items: left;
    justify-content: left;
    border-radius: 0;
    overflow-y: scroll;
  }
  h5, h6 {
    font-size: 18px;
  }

  .skill-icon {
    font-size: 16px;
  }
}



@media only screen and (min-width: 1200px) {
  
}