.single-page {

  height: 100vh;
  width: 100%;
  padding: 1.5rem 1rem;
  position: relative;
  border:none;
  display: flex;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  background-color: #e2e4e7;
}

.single-page.intro {
  background-color: #0a2f80;

}



.single-page.work-experiences {
  background-color: #0a2f80;
}

.single-page.about {
  display: flex;
  
}



/* .intro .accent-shape {
  height: 50%;
  width: 30%;
  background-color: #0047AB;
  position: absolute;
  bottom: 0;
  right: 0;
  clip-path: ellipse(100% 100% at 100% 100%);
  border: 0 transparent;
  z-index: 0;
}

.about .accent-shape {
  height: 40%;
  width: 30%;
  background-color: #0047AB;
  position: absolute;
  top: 0;
  right: 0;
  clip-path: ellipse(100% 100% at 100% 0%);
  border: 0 transparent; 
  z-index: 0;
} */

