@import url('https://fonts.googleapis.com/css?family=Titillium+Web&display=swap');
.App {
  font-family: 'Titillium Web', sans-serif;
  display: flex;   
  scroll-behavior: smooth;
  /*background-color: #0863bf ;
  /*background-color: #aba7a6;*/
}



.pages {
  display: block;
  position: relative;
  height: 100vh;
  left: 5%;
  width: 95%;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: auto;
  overscroll-behavior-y: contain;
}


@media (max-width: 600px) {
  .app {
    display: block;
  }
  .pages {
    display: block;
    position: relative;
    width: 100vw;
    top: 50px;
    left: 0;
    height: auto;
    scroll-behavior: auto;
    overflow-y: auto;
    overscroll-behavior-y: auto;

  }
}
@media (min-width: 600px) {

}

@media only screen and (min-width: 992px) {
  
}

@media only screen and (min-width: 1200px) {
}

.progress-bar {
  height: 10px;
  background-color: black;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transform-origin: 0%;
}

.accent-shape {
  display:none;
  height: 50%;
  width: 40%;
  background-color: #02285e;
  position: fixed;
  right: 0;
  bottom: 0;
  border: 0 transparent;
  /*clip-path: polygon(100% 0%, 100% 100%, 0% 100%);*/
  z-index: 1;
}

@media (min-width: 970px) {
  #author {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    position: fixed;
    right: 0;
    top: 20px;
    font-size: 30px;
    font-weight: bold;
  }
}

@media (max-width : 970px) {
  .accent-shape {
    display: none;
  }
}




.welcome-title {
  height: 100%;
  font-weight: bold;
  color: #343536;
  font-size: 120px;
}
@media (max-width: 970px ) {
  .welcome-title {
    height: 100%;
    font-weight: bold;
    color: #343536;
    font-size: calc(2.5em + 1vw);
  }
}

@media (max-width: 450px) {
  .welcom-title {
    height: 100%;
    font-weight: bold;
    color: #343536;
    font-size: 30px;
  }
}



.tiles-space {

  min-height: 700px;


  align-items: center;
  display: flex;
}

.tile-intro {
  margin-left: 180px;
  display: flex;
  text-align: left;
}

@media (max-width: 970px) {
  .tile-intro {
    margin-left: 35px;
    display: flex;
    text-align: left;
  }
}

#tintro-name {
  font-size: 80px;
  font-weight: bold;
  color: #343536;
}

@media (max-width: 970px) {
  #tintro-name {
    font-size: 40px;
  }
}


.tiles{
  /*padding-top: 50px;
  padding-bottom: 50px;*/
  height: 100%;
  max-height: 1000px;
  margin: 20px 120px 20px 120px;
  padding: 30px;
  /*background-color: #F7F5F1;*/
  /*text-align: center;*/
}



.tile-item{
  background-color: white;
  height: 100%;
  min-height: 150px;
  margin: 15px;
  display: flex;
  align-items: top;
}
.name {
  margin-top: 10px;
  font-size: 45px;
}

.tile-image {
  min-height: 200px;
}

.squares {
  padding: 20px;
}

@media (min-width: 1000px) {
  .gallary-space {
    min-height: 700px;
  }
}

@media (max-width: 970px) {
  .gallary-space {
    height : 100%;
  }
}

.gallary {
  margin: 20px 120px 20px 120px;
  padding: 10px;
}


.gallary-name{
  padding: 10px;
  text-align: left;
}

.g-name {
  font-size: 80px;
  font-weight: bold;
  color: #343536;
}

@media (max-width: 970px) {
  .g-name {
    font-size: 40px;
  }
}

.gallary-body {
  padding: 10px;
}

.g-row {
  margin: 20px;
}

#img-note {
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  align-items: center;
}


.image {
  position: relative;
  top: 10px;
  left: 5px;
  bottom: 50px;

}

.img-sec {
  overflow: hidden;

}

@media (min-width: 1000px) {
  #finApp1 {
    height: 400px;
  }

  #website1 {
    height: 400px;
  }
}

@media (max-width: 970px) {
  .gallary {
    margin: 20px 50px 20px 50px;
    padding: 10px;
  }
  #finApp1 {
    height: 200px;
  }

  #website1 {
    height: 200px;
  }
  #img-note {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    align-items: center;
  }
}

.links-space {
  min-height: 150px;
  height: 100%;
}

.links {
  align-items: center;
  padding: 40px 100px 40px 100px;
}
@media (max-width: 970px) {
  .links {
    align-items: center;
    padding: 20px 50px 20px 50px;
  }
}

#button {
  margin-left: 30px;
  margin-right: 30px;
  padding-left: 10px;
  padding-right: 10px;
}
